<template>
  <div class="min-h-contain px-5">
    <div class="m-auto">
      <div class="pb-3">
        <div class="text-left text-3xl font-bold flex">
          <p class="cursor-pointer" @click="$router.go(-1)">Documents /</p>
          <p class="text-3xl font-bold text-yellow-500 ml-2">{{ documentType }}</p>
        </div>
      </div>
      <!-- <div class="bg-white px-4 py-1 rounded-lg">
        <div class="flex gap-2 py-2 text-left"> -->
      <!-- <div class="">
            <p class="pl-2 pb-1 text-xs">Status</p>
            <select name="All" id="" class="w-36 border h-12 text-sm rounded-lg px-2">
              <option value="All">All</option>
              <option value="MANPOWER">Manpower</option>
              <option value="lMS">LMS</option>
              <option value="MARKETPLACE">Marketplace</option>
              <option value="PROJECT">Project</option>
              <option value="EDUCATION">Education</option>
            </select>
          </div> -->
      <!-- <div class="">
            <p class="pl-2 pb-1 text-xs">Start Date</p>
            <select name="Choose Date" id="" class="w-36 border h-12 text-sm rounded-lg px-2">
              <option value="Choose Date">Choose Date</option>
              <option value="ADMIN">Admin</option>
              <option value="USER">User</option>
              <option value="CMS_ADMIN">Cms_Admin</option>
              <option value="REGISTRANT">Registrant</option>
            </select>
          </div> -->
      <!-- <div class="">
            <p class="pl-2 pb-1 text-xs">End Date</p>
            <select name="Choose Date" id="" class="w-36 border h-12 text-sm rounded-lg px-2">
              <option value="Choose Date">Choose Date</option>
              <option value="ADMIN">Admin</option>
              <option value="USER">User</option>
              <option value="CMS_ADMIN">Cms_Admin</option>
              <option value="REGISTRANT">Registrant</option>
            </select>
          </div> -->
      <!-- <div>
            <p class="pl-2 pb-1 text-xs">Short By</p>
            <select name="Last Modified" id="" class="w-36 border h-12 text-sm rounded-lg px-2">
              <option value="last_modified">Last Modified</option>
              <option value="first_created">First Created</option>
              <option value="last_created">Last Created</option>
            </select>
          </div> -->
      <!-- <div class="w-full">
            <p class="pl-2 pb-1 text-xs">Search</p>
            <div class="relative">
              <span class="inline-block absolute top-1/2 left-3 transform -translate-y-1/2"><Search color="neutral-400" /></span>
              <TextField type="text" :withIcon="true" placeholder="Enter company name, email or pic" :borderEnabled="true" v-model="searchKeyword" />
              <input class="h-12 border focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg pl-3" />
            </div>
          </div> -->
      <!-- </div> -->
      <!-- <div class="mt-3 mb-3 flex gap-2">
          <button class="text-white w-28 h-11 bg-yellow-300 rounded-lg font-bold hover:bg-yellow-600">Search</button>
          <button class="text-yellow w-36 h-11 p-2 bg-pink-100 rounded-lg font-bold hover:bg-yellow-600 hover:text-white">Apply Filter</button>
          <button class="p-2 text-yellow-light w-32 h-11 font-bold text-center">Clear</button>
        </div> -->
      <!-- </div> -->
    </div>
    <div class="bg-white py-3 px-4 mt-3 rounded-lg">
      <table class="w-full text-left">
        <tr class="text-standart bg-gray-300">
          <th class="py-3 px-3">Document Name</th>
          <!-- <th class="py-3 px-3">Cooperation Type</th> -->
          <th class="py-3 px-3">Document Type</th>
          <th class="py-3 px-3" v-show="isNotImageActive">Status</th>
          <th class="py-3 px-3">Created Date</th>
          <th class="py-3 px-3">Last Modified</th>
          <th class="py-3 px-3">Modified By</th>
          <th class="py-3 px-3">Action</th>
        </tr>
        <tr class="text-sm bg-gray-100 w-screen" v-for="(data, idx) in dataTable.content" :key="idx">
          <td class="px-3 py-3">{{ data.registrationDocument.name }}</td>
          <!-- <td class="px-3 py-3 capitalize">{{ data.cooperationType ? data.cooperationType : '-' }}</td> -->
          <td class="px-3 py-3">{{ data.registrationDocument.type }}</td>
          <td :class="`px-3 py-3 ${data.status.toUpperCase() === 'NEED_APPROVAL' || data.status.toUpperCase() === 'REJECTED' ? 'text-red-500' : 'text-green-500'}`" v-show="isNotImageActive">
            {{ data.status === 'NEED_APPROVAL' ? 'Need Approval' : data.status === 'REJECTED' ? 'Rejected' : 'Approved' }}
          </td>
          <td class="px-3 py-3">{{ moment(data.createdDate) }}</td>
          <td class="px-3 py-3">{{ moment(data.updatedDate) }}</td>
          <td class="px-3 py-3">{{ data.updatedBy }}</td>
          <td class="px-3 py-3 gap-1">
            <button class="text-yellow-light w-24 h-9 p-2 bg-yellow-secondary rounded-lg font-bold" @click="DocumentApprove(data.id)">Review</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'document-table',
  data() {
    return {
      isNotImageActive: false,
      nda: '',
      contract: '',
      dataTable: []
    }
  },
  mounted() {
    this.getDataMitra()
  },
  methods: {
    ...mapActions('client', ['GET_DOCUMENT_LIST']),
    DocumentApprove(id) {
      this.$router.push({ path: '/' + this.$route.meta.name + '/approve', query: { is: this.$route.query.is, id } })
    },
    isActivePage(route) {
      if (route) return JSON.parse(localStorage.getItem('talent')).ctt
      else return JSON.parse(localStorage.getItem('talent')).nda
    },
    async getDataMitra() {
      this.dataTable = await this.GET_DOCUMENT_LIST({
        page: 0,
        size: 10,
        registrationDocumentName: this.documentType
      })
      this.isNotImageActive = this.isNotImage(this.dataTable.content[0].registrationDocument.type)
    },
    isNotImage(type) {
      if (type !== 'IMAGE') return true
    },
    moment(date) {
      return moment(date).local().format('DD MMM YYYY HH:mm')
    }
  },
  computed: {
    documentType() {
      return this.$route.query.is
    }
  }
}
</script>
