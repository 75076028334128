var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-contain px-5"
  }, [_c('div', {
    staticClass: "m-auto"
  }, [_c('div', {
    staticClass: "pb-3"
  }, [_c('div', {
    staticClass: "text-left text-3xl font-bold flex"
  }, [_c('p', {
    staticClass: "cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Documents /")]), _c('p', {
    staticClass: "text-3xl font-bold text-yellow-500 ml-2"
  }, [_vm._v(_vm._s(_vm.documentType))])])])]), _c('div', {
    staticClass: "bg-white py-3 px-4 mt-3 rounded-lg"
  }, [_c('table', {
    staticClass: "w-full text-left"
  }, [_c('tr', {
    staticClass: "text-standart bg-gray-300"
  }, [_c('th', {
    staticClass: "py-3 px-3"
  }, [_vm._v("Document Name")]), _c('th', {
    staticClass: "py-3 px-3"
  }, [_vm._v("Document Type")]), _c('th', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isNotImageActive,
      expression: "isNotImageActive"
    }],
    staticClass: "py-3 px-3"
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "py-3 px-3"
  }, [_vm._v("Created Date")]), _c('th', {
    staticClass: "py-3 px-3"
  }, [_vm._v("Last Modified")]), _c('th', {
    staticClass: "py-3 px-3"
  }, [_vm._v("Modified By")]), _c('th', {
    staticClass: "py-3 px-3"
  }, [_vm._v("Action")])]), _vm._l(_vm.dataTable.content, function (data, idx) {
    return _c('tr', {
      key: idx,
      staticClass: "text-sm bg-gray-100 w-screen"
    }, [_c('td', {
      staticClass: "px-3 py-3"
    }, [_vm._v(_vm._s(data.registrationDocument.name))]), _c('td', {
      staticClass: "px-3 py-3"
    }, [_vm._v(_vm._s(data.registrationDocument.type))]), _c('td', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isNotImageActive,
        expression: "isNotImageActive"
      }],
      class: "px-3 py-3 ".concat(data.status.toUpperCase() === 'NEED_APPROVAL' || data.status.toUpperCase() === 'REJECTED' ? 'text-red-500' : 'text-green-500')
    }, [_vm._v(" " + _vm._s(data.status === 'NEED_APPROVAL' ? 'Need Approval' : data.status === 'REJECTED' ? 'Rejected' : 'Approved') + " ")]), _c('td', {
      staticClass: "px-3 py-3"
    }, [_vm._v(_vm._s(_vm.moment(data.createdDate)))]), _c('td', {
      staticClass: "px-3 py-3"
    }, [_vm._v(_vm._s(_vm.moment(data.updatedDate)))]), _c('td', {
      staticClass: "px-3 py-3"
    }, [_vm._v(_vm._s(data.updatedBy))]), _c('td', {
      staticClass: "px-3 py-3 gap-1"
    }, [_c('button', {
      staticClass: "text-yellow-light w-24 h-9 p-2 bg-yellow-secondary rounded-lg font-bold",
      on: {
        "click": function click($event) {
          return _vm.DocumentApprove(data.id);
        }
      }
    }, [_vm._v("Review")])])]);
  })], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }